import { clearAllEventStreams, getQuoteType } from "../utils";
import {
  deleteQuote,
  deleteQuoteResponses,
  updateQuote,
} from "../services/quote";
import { addContact } from "../redux/actions/contact";
import { clearSwitchPrimaryDoneCarrierIdsInRedux } from "./switchPrimary";
import { getQueryParams } from "./queryParams";
import { handleUpdateSession } from "../services/utils";
import moment from "moment";
import store from "../redux/store";
import { tryCatch } from "./errors";
import { updateAccountinPg } from "../services/account";
import { updateIsFetching } from "../redux/actions/fetching";
import { upsertSpouseDetails } from "../services/spouse";

export const responsePageResubmit = async (sfAgentId = "", options = {}) => {
  const {
    isAutoPolicySelected,
    isHomePolicySelected,
    autoEffectiveDate,
    homeEffectiveDate,
    resubmitWithMyLogins,
  } = options;

  try {
    store.dispatch(updateIsFetching(true));
    const { quoteEvents } = window;
    const contact = store.getState().contact;
    const session = store.getState().session;
    const selectedAutoQuoteId = isAutoPolicySelected ? contact.autoQuoteId : "";
    const selectedHomeQuoteId = isHomePolicySelected ? contact.homeQuoteId : "";
    store.dispatch(
      addContact({
        responsePageResubmit: true,
        resubmitAuto: isAutoPolicySelected,
        resubmitHome: isHomePolicySelected,
      })
    );
    clearAllEventStreams(
      quoteEvents?.filter(
        (event) =>
          event.url.includes(selectedAutoQuoteId) ||
          event.url.includes(selectedHomeQuoteId)
      )
    );

    const { spouseAutoQuoteId, spouseHomeQuoteId, homeQuoteId, autoQuoteId } =
      contact ?? {};
    await updateAccountinPg(contact.accountId, {
      response_count: 0, //need to check the count for the not resubmitted one
      last_client_rater_quote__c: moment().format("YYYY-MM-DD HH:mm:ssZ"),
    });
    if (homeQuoteId && isHomePolicySelected) {
      await updateQuote({
        quoteId: contact.homeQuoteId,
        effective_date__c: homeEffectiveDate,
        ...(!!sfAgentId &&
          resubmitWithMyLogins && { lastsubmittedby__c: sfAgentId }),
        status__c: null,
      });
      await deleteQuoteResponses(contact.homeQuoteId);
    }

    if (autoQuoteId && isAutoPolicySelected) {
      await updateQuote({
        quoteId: contact.autoQuoteId,
        effective_date__c: autoEffectiveDate,
        ...(!!sfAgentId &&
          resubmitWithMyLogins && { lastsubmittedby__c: sfAgentId }),
        status__c: null,
      });
      await deleteQuoteResponses(contact.autoQuoteId);
    }
    if (spouseHomeQuoteId && isHomePolicySelected) {
      await updateQuote({
        quoteId: spouseHomeQuoteId,
        effective_date__c: homeEffectiveDate,
        ...(!!sfAgentId &&
          resubmitWithMyLogins && { lastsubmittedby__c: sfAgentId }),
        status__c: null,
      });
      await deleteQuoteResponses(spouseHomeQuoteId);
    }
    if (spouseAutoQuoteId && isAutoPolicySelected) {
      await updateQuote({
        quoteId: spouseAutoQuoteId,
        effective_date__c: autoEffectiveDate,
        ...(!!sfAgentId &&
          resubmitWithMyLogins && { lastsubmittedby__c: sfAgentId }),
        status__c: null,
      });
      await deleteQuoteResponses(spouseAutoQuoteId);
    }

    await handleUpdateSession({
      uuid: session.uuid,
      completed: false,
      synced: false,
      qti_id: null,
      qti_option_id: null,
      contact_data: JSON.stringify({
        ...contact,
        ...{ spouseHomeQuoteId: spouseHomeQuoteId ?? null },
        ...{ spouseAutoQuoteId: spouseAutoQuoteId ?? null },
      }),
    });
  } catch (e) {
    console.log(e);
  }
};

export const resetQuotesStatus = async () => {
  const quoteType = getQuoteType();
  store.dispatch(
    addContact({
      addSkippedHomeQuote: false,
      addSkippedAutoQuote: false,
    })
  );
  try {
    const { quoteEvents } = window;

    clearAllEventStreams(quoteEvents);

    const contact = store.getState().contact;
    const session = store.getState().session;
    const property = store.getState().property;
    const drivers = store.getState().drivers;

    let spouseHomeId = null;
    let spouseAutoId = null;
    if (
      !["CA"].includes(
        (property.administrative_area_level_1 || "").toUpperCase()
      ) &&
      contact.spouseId
    ) {
      if (contact.homeQuoteId) {
        const spouseQuote = await upsertSpouseDetails({
          primaryQuoteId: contact.homeQuoteId,
          spouseQuoteId: contact.spouseHomeQuoteId,
          lob: "home",
        });

        spouseHomeId = spouseQuote.data;

        store.dispatch(addContact({ spouseHomeQuoteId: spouseHomeId }));
      }

      if (contact.autoQuoteId) {
        if (
          drivers.filter(
            (driver) => driver.relationship === "Spouse" && driver.selected
          ).length
        ) {
          const spouseQuote = await upsertSpouseDetails({
            primaryQuoteId: contact.autoQuoteId,
            spouseQuoteId: contact.spouseAutoQuoteId,
            lob: "auto",
          });
          spouseAutoId = spouseQuote.data;
          store.dispatch(addContact({ spouseAutoQuoteId: spouseAutoId }));
        } else {
          if (spouseHomeId) {
            await updateQuote({
              quoteId: spouseHomeId,
              quote_as_package__c: "No",
              quote_type__c: quoteType,
            });
          }
          if (contact.spouseAutoQuoteId) {
            await deleteQuoteResponses(contact.spouseAutoQuoteId);
            await deleteQuote(contact.spouseAutoQuoteId);
            store.dispatch(addContact({ spouseAutoQuoteId: undefined }));
          }
        }
      }
    }

    await updateAccountinPg(contact.accountId, {
      response_count: 0,
      last_client_rater_quote__c: moment().format("YYYY-MM-DD HH:mm:ssZ"),
    });
    if (contact.homeQuoteId) {
      await updateQuote({
        quoteId: contact.homeQuoteId,
        status__c: null,
        quote_type__c: quoteType,
      });
      await deleteQuoteResponses(contact.homeQuoteId);
    }

    if (contact.autoQuoteId) {
      await updateQuote({
        quoteId: contact.autoQuoteId,
        status__c: null,
        quote_type__c: quoteType,
      });
      await deleteQuoteResponses(contact.autoQuoteId);
    }
    if (spouseHomeId) {
      await updateQuote({
        quoteId: spouseHomeId,
        status__c: null,
        quote_type__c: quoteType,
      });
      await deleteQuoteResponses(spouseHomeId);
    }
    if (spouseAutoId) {
      await updateQuote({
        quoteId: spouseAutoId,
        status__c: null,
        quote_type__c: quoteType,
      });
      await deleteQuoteResponses(spouseAutoId);
    }

    await handleUpdateSession({
      uuid: session.uuid,
      completed: false,
      synced: false,
      qti_id: null,
      qti_option_id: null,
      contact_data: JSON.stringify({
        ...contact,
        ...(spouseHomeId
          ? { spouseHomeQuoteId: spouseHomeId }
          : { spouseHomeQuoteId: null }),
        ...(spouseAutoId
          ? { spouseAutoQuoteId: spouseAutoId }
          : { spouseAutoQuoteId: null }),
      }),
    });
  } catch (e) {
    console.log(e);
  }
};

export const reSubmitQuotesWithOwnLogins = async (sfAgentId) => {
  const { contact } = store.getState();
  try {
    store.dispatch(updateIsFetching(true));
    if (contact.homeQuoteId)
      await updateQuote({
        quoteId: contact.homeQuoteId,
        lastsubmittedby__c: sfAgentId,
      });
    if (contact.autoQuoteId)
      await updateQuote({
        quoteId: contact.autoQuoteId,
        lastsubmittedby__c: sfAgentId,
      });
    if (contact.spouseHomeQuoteId)
      await updateQuote({
        quoteId: contact.spouseHomeQuoteId,
        lastsubmittedby__c: sfAgentId,
      });
    if (contact.spouseAutoQuoteId)
      await updateQuote({
        quoteId: contact.spouseAutoQuoteId,
        lastsubmittedby__c: sfAgentId,
      });
    await resetQuotesStatus();
  } catch (error) {
    console.log("Something went wrong", error);
  }
};

export const reSubmitQuotesOriginalAgentLogins = async () => {
  const { resubmitAs } = tryCatch(
    () => getQueryParams(),
    () => ({})
  );
  if (resubmitAs) {
    return reSubmitQuotesWithOwnLogins(resubmitAs);
  }

  store.dispatch(updateIsFetching(true));
  clearSwitchPrimaryDoneCarrierIdsInRedux();
  await resetQuotesStatus();
};
