import React, { useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import Header, { NavButton } from "../components/Header";
import { theme } from "../assets/theme";
import styled from "styled-components";
import GooseheadFlightLines from "../assets/images/main/Goosehead_FlightLines.jpg";
import AgentFeedback from "../components/AgentFeedback/AgentFeedback";
import { useNavigate } from "react-router-dom";
import { clearLocalSessionId } from "../services/session";

const StyledPrimaryHeader = styled.h1`
  color: ${theme.green_new};
  font-family: ${theme.font_primary};
  font-size: 96px;
  line-heigth: 123.36px;
  font-weight: 400;
`;

const StyledSecondaryHeader = styled.h2`
  color: ${theme.green_new};
  font-family: ${theme.font_primary};
  font-size: 36px;
  line-heigth: 46.26px;
  font-weight: 400;
`;

const StyledText = styled.p`
  color: #464646;
  font-family: "Rethink Sans Variable", sans-serif;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 20px;
`;

const ErrorContainer = styled.div`
  position: absolute;
  top: 33%;
  left: 10%;
  transform: translate(-10%, -33%);
`;

const ImageContainer = styled.img`
  position: absolute;
  top: 60;
  right: 0;
  width: 66vw;
  height: auto;
  z-index: -1;
`;

const StyledPopup = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
`;

function ErrorPage({ error, resetErrorBoundary }) {
  const [showFeedback, setShowFeedback] = useState(false);

  return (
    <>
      <Header resetErrorBoundary={resetErrorBoundary}></Header>
      <ImageContainer src={GooseheadFlightLines} alt="Goosehead Flight Lines" />
      <ErrorContainer>
        <StyledPrimaryHeader>404</StyledPrimaryHeader>
        <StyledSecondaryHeader>
          Ooops, something went wrong!
        </StyledSecondaryHeader>
        <StyledText>
          Aviator is having some technical difficulties at this time.
          <br />
          Please provide some feedback describing your experience or choose
          &rsquo;Return to Aviator&rsquo;
        </StyledText>
        <NavButton
          style={{ marginRight: "10px" }}
          $color="white"
          $backGroundColor={theme.green_new}
          $border={`1px solid ${theme.green_new}`}
          onClick={resetErrorBoundary}
        >
          Return to Aviator
        </NavButton>
        <NavButton
          $color={theme.green_new}
          $border={`1px solid ${theme.green_new}`}
          onClick={() => setShowFeedback(true)}
        >
          Submit Feedback
        </NavButton>
      </ErrorContainer>
      {showFeedback && (
        <StyledPopup>
          <AgentFeedback
            isActive={true}
            close={() => setShowFeedback(false)}
          ></AgentFeedback>
        </StyledPopup>
      )}
    </>
  );
}

const AviatorErrorBoundary = ({ children }) => {
  const navigate = useNavigate();

  return (
    <ErrorBoundary
      FallbackComponent={ErrorPage}
      onReset={() => {
        clearLocalSessionId();
        navigate("/");
      }}
    >
      {children}
    </ErrorBoundary>
  );
};

export { AviatorErrorBoundary, ErrorPage };
