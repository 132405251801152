import { useDispatch, useSelector } from "../../redux/hooks";
import { useState } from "react";
import styled from "styled-components";
import { Dropdown } from "react-bootstrap";
import { Chart } from "./Chart";
import { ReactComponent as LocationIcon } from "../../assets/images/main/location-outline-20.svg";
import { ReactComponent as MarketShareIcon } from "../../assets/images/components/footer/CarrierMarketShare.svg";
import { ReactComponent as CaretDown } from "../../assets/images/components/response-page/caretDownWhiteFill.svg";
import { ReactComponent as OpenInNewTab } from "../../assets/images/components/response-page/openInNewTab.svg";
import { ReactComponent as NoDataWarning } from "../../assets/images/components/response-page/warning-20.svg";
import { ReactComponent as LocationMissing } from "../../assets/images/components/response-page/location-error.svg";
import { MinimizeButton } from "../ChatBot/styled";
import { StyledDropdown } from "../new_design/filters/styled";
import { StyledFooterToolContainer } from "../new_design/styled";
import { addContact } from "../../redux/actions/contact";
import { getSessionPropertyLob } from "../../utilsMisc";
import { getMarketShareData } from "./utils";
import { statesFull } from "../../constants";
import Error from "./Error";

type MarketShareProps = {
  isActive?: boolean;
  close: () => void;
};

/** Component for displaying policies sold as charts. */
const StyledMarketShareTool = styled.div<{ height: string }>`
  font-family: Rethink sans Variable;
  box-shadow: -6px 0 15.7px 0 rgba(0, 0, 0, 0.1);
  border-bottom: solid 1px #d8d7d4;
  min-width: 411px;
  & header {
    font-family: Rethink sans Variable;

    & .successFailDropdown {
      background: transparent;
      border: none;
    }

    & .totalPoliciesFilter {
      & .dropdown-menu {
        padding: 0;
        min-width: 220px;
        font-size: 12px;
        transform: translate(-30px, 38px) !important;
        width: 238px !important;
        border: none !important;
        gap: 0;

        button:nth-child(even) {
          border-radius: 0 0 5px 5px;
        }

        & button {
          background: transparent;
          border: none;
          text-align: left;
          font-size: 12px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #2d2c2a;
          padding: 6px 15px 6px 15px;
          border: solid 1px #d5d5d5;
          border-top: none;
        }
      }
    }

    & .dropdown-toggle,
    & .dropdown-toggle:focus,
    & .dropdown-toggle:active,
    & .dropdown-toggle:hover {
      background-color: #006b67 !important;
      border: none !important;
      box-shadow: none !important;
      color: #fff !important;
      font-weight: 600;
      font-size: 16px;
      padding: 0;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;

      ::after {
        display: none;
      }
    }
  }

  & .body {
    font-family: Rethink sans Variable;
    font-size: 14px;
    font-weight: normal;
    color: #222;

    & .filter {
      & button {
        background: transparent;
        border: none;
      }

      display: flex;
      align-items: center;
      justify-content: space-between;
      flex: 1;

      & > [class*="col-"] {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      & .location {
        padding: 10px 0 6px 20px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        font-weight: 500;
        font-size: 16px;
      }

      & .term {
        padding-right: 20px;
        align-items: flex-end;
        & .dropdown-toggle,
        & .dropdown-toggle:focus,
        & .dropdown-toggle:active,
        & .dropdown-toggle:hover {
          border: solid 1px #118f00 !important;
        }

        & .dropdown-menu {
          min-width: 100px;
          padding: 5px;
          font-size: 13px;
          border-radius: 8px;
          & button {
            font-weight: 500;
          }
        }
      }
    }

    & .administrativeLevel {
      border-bottom: solid 1px #d8d7d4;

      & button {
        margin: 9px;
        width: 100%;
      }

      & .active {
        border-bottom: solid 3px #017429;

        & button {
          font-weight: 500;
        }
      }
    }

    & .chart {
      margin: 10px 20px 0 20px;

      & .chartLabel {
        font-size: 15px;
        font-weight: 600;
        display: flex;
        gap: 5px;
      }

      & .graph {
        padding: 10px 0 10px 0;
      }
    }
  }

  & .footer {
    margin: 10px 20px 10px 20px;
    display: flex;
    justify-content: flex-end;

    & .salesForceCMS {
      font-family: "Rethink Sans Variable";
      font-size: 12px;
      font-weight: 500;
      color: #6d6d6d;
      cursor: pointer;
    }
  }
`;

const TOTAL_POLICIES_LABEL = {
  success: "Total Policies Sold",
  failed: "Total Policies Failed",
};

const ERROR_CONTENT = {
  noPoliciesSingleLob: "No policies sold within this selected time frame",
  countyUndefined: "Cannot determine county",
  noPolicies: "No policies sold within this selected time frame",
};

const MarketShare = ({ isActive = false, close }: MarketShareProps) => {
  const dispatch = useDispatch();

  /** Fetch market share data */
  const fetchMarketShareData = async (state: string, county?: string) => {
    try {
      const marketShareData = await getMarketShareData(state, county);
      marketShareData && setChartData(marketShareData?.success?.state?.[90]);
      console.log("Market share data:", marketShareData); //temporary, to make testing easy for QA
      dispatch(addContact({ ...contact, marketShareData }));
    } catch (error) {
      console.error("Error fetching market share data:", error);
    }
  };

  const property = useSelector((store) => store.property) ?? {};
  const {
    administrative_area_level_1: state,
    administrative_area_level_2: county,
  } = property;
  const contact = useSelector((store) => store.contact) ?? {};
  const { homeQuoteId, autoQuoteId, marketShareData } = contact;

  /** Property type - condo, renter's, dwelling or Home */
  const propertyType = getSessionPropertyLob() ?? "Home";

  /** Chart default view is success quotes, 90 days with state in context */
  const [chartData, setChartData] = useState(
    marketShareData?.success?.state?.[90]
  );
  /** By default show policies generated on successful quotes */
  const [quoteState, setQuoteState] = useState<"success" | "failed">("success");

  /** Controls 90 days and 30 days term filter */
  const [term, setTerm] = useState<{ length: number | null; show: boolean }>({
    length: null,
    show: false,
  });

  /** Controls display of policies sold dropdown */
  const [showPolicyDropdown, setShowPolicyDropdown] = useState(false);

  /** Controls state and county filter */
  const [administrativeArea, setAdministrativeArea] = useState("state");

  /** Call market share API only once */
  if (!marketShareData && state) {
    fetchMarketShareData(state, county);
  }
  /** We need at least State to build the graphs */
  if (!state) return null;

  const errorBoxHeight = autoQuoteId && homeQuoteId ? "378px" : "210px";

  return (
    <StyledFooterToolContainer $display={isActive ? "flex" : "none"}>
      <StyledMarketShareTool height={autoQuoteId ? "500px" : "350px"}>
        <header>
          <div className="left totalPoliciesFilter">
            <MarketShareIcon />
            <StyledDropdown
              show={showPolicyDropdown}
              $width={"200px"}
              $menuWidth={"50px"}
              backgroundColor={term.length ? "#d0fcc9cc" : "#fff"}
              border={"none"}
            >
              <Dropdown.Toggle
                onClick={() => setShowPolicyDropdown(!showPolicyDropdown)}
              >
                <span style={{ paddingRight: "10px" }}>
                  {TOTAL_POLICIES_LABEL[quoteState]}
                </span>
                <CaretDown />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <button
                  type="button"
                  className="success"
                  onClick={() => {
                    setQuoteState("success");
                    setAdministrativeArea("state");
                    setTerm({ length: term.length ? 90 : null, show: false });
                    setChartData(marketShareData?.success?.state?.["90"]);
                    setShowPolicyDropdown(false);
                  }}
                >
                  {TOTAL_POLICIES_LABEL["success"]}
                </button>
                <button
                  type="button"
                  className="failed"
                  onClick={() => {
                    setQuoteState("failed");
                    setAdministrativeArea("state");
                    setTerm({ length: term.length ? 90 : null, show: false });
                    setChartData(marketShareData?.failed?.state?.["90"]);
                    setShowPolicyDropdown(false);
                  }}
                >
                  {TOTAL_POLICIES_LABEL["failed"]}
                </button>
              </Dropdown.Menu>
            </StyledDropdown>
          </div>
          <MinimizeButton
            width="28px"
            height="28px"
            onClick={() => {
              setAdministrativeArea("state");
              setTerm({ length: null, show: false });
              setShowPolicyDropdown(false);
              setQuoteState("success");
              setChartData(marketShareData?.success?.state?.["90"]);
              close();
            }}
            aria-label="minimize crm tool"
          />
        </header>
        <section className="body">
          <div className="filter administrativeLevel">
            <div
              className={`col-md-6 ${
                administrativeArea === "state" ? "active" : ""
              }`}
            >
              <button
                type="button"
                onClick={() => {
                  setTerm({
                    ...term,
                    length: term.length ? 90 : null,
                    show: false,
                  });
                  setAdministrativeArea("state");
                  setShowPolicyDropdown(false);
                  setChartData(marketShareData?.[quoteState]?.state?.["90"]);
                }}
              >
                State
              </button>
            </div>
            <div
              className={`col-md-6 ${
                administrativeArea === "county" ? "active" : ""
              }`}
            >
              <button
                type="button"
                onClick={() => {
                  setShowPolicyDropdown(false);
                  setAdministrativeArea("county");
                  setTerm({
                    ...term,
                    length: term.length ? 90 : null,
                    show: false,
                  });
                  setChartData(marketShareData?.[quoteState]?.county?.["90"]);
                }}
              >
                County
              </button>
            </div>
          </div>
          {administrativeArea === "county" && !county ? (
            <Error
              content={ERROR_CONTENT["countyUndefined"]}
              height={errorBoxHeight}
              icon={<LocationMissing />}
            />
          ) : (
            <>
              <div className="filter">
                <div className="col-md-8 location">
                  <LocationIcon />
                  {administrativeArea === "state"
                    ? statesFull[state as keyof typeof statesFull] ?? state
                    : county}
                </div>
                <div className="col-md-4 term">
                  <StyledDropdown
                    show={term.show}
                    $backgroundColor={term.length ? "#d0fcc9cc" : "#fff"}
                    $borderColor={term.length ? "#118f00" : "#d6d6d6"}
                    $color={term.length ? "#118f00" : "#222"}
                    $width={"100px"}
                    $menuWidth={"50px"}
                  >
                    <Dropdown.Toggle
                      onClick={() => setTerm({ ...term, show: !term.show })}
                    >
                      <span>{`${term.length ?? 90} days`}</span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <button
                        type="button"
                        hidden={term.length === 90 || !term.length}
                        onClick={() => {
                          setShowPolicyDropdown(false);
                          setTerm({
                            ...term,
                            length: term.length ? 90 : null,
                            show: false,
                          });
                          setChartData(
                            marketShareData?.[quoteState]?.county?.["90"]
                          );
                        }}
                      >
                        90
                      </button>
                      <button
                        type="button"
                        hidden={term.length === 30}
                        onClick={() => {
                          setShowPolicyDropdown(false);
                          setTerm({ ...term, length: 30, show: false });
                          setChartData(
                            marketShareData?.[quoteState]?.county?.["30"]
                          );
                        }}
                      >
                        30
                      </button>
                    </Dropdown.Menu>
                  </StyledDropdown>
                </div>
              </div>
              {!chartData?.auto?.length && !chartData?.home?.length ? (
                <Error
                  content={ERROR_CONTENT["noPolicies"]}
                  height={errorBoxHeight}
                  icon={<NoDataWarning />}
                />
              ) : (
                <>
                  {homeQuoteId && (
                    <div className="chart">
                      <div className="chartLabel">
                        {!chartData?.home?.length && <NoDataWarning />}
                        {propertyType === "Renter's" ? "Renters" : propertyType}
                      </div>
                      <div className="graph">
                        {chartData?.home?.length ? (
                          <Chart data={chartData?.home} />
                        ) : (
                          <Error
                            content={ERROR_CONTENT["noPoliciesSingleLob"]}
                            height={"auto"}
                            singleLobError={true}
                          />
                        )}
                      </div>
                    </div>
                  )}
                  {autoQuoteId && (
                    <div className="chart">
                      <div className="chartLabel">
                        {!chartData?.auto?.length && <NoDataWarning />}
                        Auto
                      </div>
                      <div className="graph">
                        {chartData?.auto?.length ? (
                          <Chart data={chartData?.auto} />
                        ) : (
                          <Error
                            content={ERROR_CONTENT["noPoliciesSingleLob"]}
                            height={"auto"}
                            singleLobError={true}
                          />
                        )}
                      </div>
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </section>
        <div className="footer">
          <a
            target="_blank"
            rel="noreferrer"
            className="salesForceCMS"
            href="https://goosehead.lightning.force.com/analytics/dashboard/0FK3c000000CtF0GAK"
          >
            <OpenInNewTab />
            <span style={{ marginLeft: "5px" }}>Salesforce CMS</span>
          </a>
        </div>
      </StyledMarketShareTool>
    </StyledFooterToolContainer>
  );
};

export default MarketShare;
