import styled from "styled-components";

export const MainWrapper = styled.div`
  ${(props) =>
    `
      flex-grow: 1;
      min-height: ${
        props.windowheight ? props.windowheight * 0.8 : window.innerHeight * 0.8
      }px;
      background-color: ${props.dark ? props.theme.primary : "white"};
      margin-bottom: 4rem;
  `}
`;
export const TextContainer = styled.div`
  padding-top: 40px;
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : "700px")};
  margin: auto;
`;

export const ResponseWrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  > * {
    min-height: 0;
    flex-shrink: 0;
  }
`;

export const Container = styled.div`
  width: 100%;
  max-width: ${(props) => props.maxWidth ?? "700px"};
`;
