import styled from "styled-components";
import { useSelector } from "../redux/hooks";
import {
  AvaButton,
  FeedbackButton,
  NotesButton,
  Popup,
  MarketShareButton,
} from "./FooterButtons";
import { useState } from "react";
import { ActiveTab } from "./types";
import { isResponsesPage } from "../utilsMisc";
import { MARKET_SHARE } from "../constants";

const StyledFooter = styled.footer`
  background: white;
  position: sticky;
  top: calc(100vh - 32px);
  bottom: 0;
  width: 100%;
  height: 32px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: stretch;
  gap: 10px;
  padding: 0 10px;
  border-top: solid 1px #d8d8d8;
  z-index: 1000;
`;

const Footer = () => {
  const contact = useSelector((store) => store.contact);

  const [activeTab, setActiveTab] = useState<ActiveTab>();
  const close = () => setActiveTab(undefined);
  const toggleVisibility = (tab: ActiveTab) => {
    if (activeTab === tab) {
      close();
    } else {
      setActiveTab(tab);
    }
  };

  const hasAva = contact.user && window.location.pathname !== "/";
  const hasNotesFeedback =
    contact.user &&
    !["/", "/address", "/login"].includes(window.location.pathname);
  const hasPopover = hasAva || hasNotesFeedback;

  return (
    <StyledFooter>
      {isResponsesPage() && (
        <MarketShareButton
          $isOpen={activeTab === MARKET_SHARE}
          onClick={() => toggleVisibility(MARKET_SHARE)}
        />
      )}
      {hasNotesFeedback && (
        <>
          <NotesButton
            $isOpen={activeTab === "notes"}
            onClick={() => toggleVisibility("notes")}
          />
          <FeedbackButton
            $isOpen={activeTab === "feedback"}
            onClick={() => toggleVisibility("feedback")}
          />
        </>
      )}
      {hasAva && (
        <AvaButton
          $isOpen={activeTab === "ava"}
          onClick={() => toggleVisibility("ava")}
        />
      )}
      {hasPopover && <Popup activeTab={activeTab} close={close} />}
    </StyledFooter>
  );
};

export default Footer;
